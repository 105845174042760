// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.search-bar {
    border-radius: 20px;
    padding: 8px;
    width: 200px;
}

.sidebar {
    background-color: #ffffff;
    border-right: 1px solid #ddd;
    height: 100vh;
    width: 200px;
}

.username-link {
    font-weight: bold; 
}

.username-link:hover {
    text-decoration: underline !important;
}
`, "",{"version":3,"sources":["webpack://./src/components.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".logo {\n    font-size: 1.5rem;\n    font-weight: bold;\n}\n\n.search-bar {\n    border-radius: 20px;\n    padding: 8px;\n    width: 200px;\n}\n\n.sidebar {\n    background-color: #ffffff;\n    border-right: 1px solid #ddd;\n    height: 100vh;\n    width: 200px;\n}\n\n.username-link {\n    font-weight: bold; \n}\n\n.username-link:hover {\n    text-decoration: underline !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
